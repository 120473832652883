import styled from '@emotion/styled'

export const StarsPochingoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const StarItemContainer = styled.div`
  position: absolute;
  animation: ${(props) => `mymove${props.num}`} ${props => props.time}s;
  animation-timing-function: ease-in-out;
  animation-delay: ${props => props.delay}s;
  bottom: 0;
  opacity: 0;
  left: ${props => props.startLeft}px;
  bottom: ${props => props.startBottom}px;
  @keyframes ${(props) => `mymove${props.num}`} {
    0% {
      /* left: ${props => props.startLeft}px; */
      opacity: 0;
    }
    30% {
      opacity: 0.8;
    }
    80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      left: ${props => props.endLeft}px;
      /* bottom: 400px; */
      bottom: ${props => props.moveHeight}px;
    }
  }
  span {
    font-size: ${props => props.size}px;
  }
`;